<template>
  <div class="editor-area">
    <h2>New Announcement</h2>
    <label for="title">Title</label>
    <input type="text" v-model="title" id="title" />
    <label for="subtitle">Sub-Title</label>
    <input type="text" v-model="subtitle" id="subtitle" />
    <hr />
    <label for="body">Body</label>
    <editor id="body" api-key="no-api-key" v-model="body" :init="config" />
    <button @click="submit">Submit</button>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import Editor from "@tinymce/tinymce-vue";
import apiInterface from "@/api/ApiInterface";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    Editor,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const title = ref("");
    const subtitle = ref("");
    const date = ref(new Date());
    const body = ref("");

    const config = reactive({
      menubar: false,
      plugins: "lists link image emoticons code paste",
      // eslint-disable-next-line @typescript-eslint/camelcase
      paste_data_images: true,
      toolbar:
        "styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons",
    });

    const submit = async () => {
      try {
        await apiInterface.createAnnouncement(
          title.value,
          subtitle.value,
          body.value,
          new Date("12-12-2021")
        );
      } catch {
        alert("There was an error");
        return;
      }
      store.dispatch("getAnnouncements");
      router.push({ name: "ListAnnouncements" });
    };

    return {
      title,
      subtitle,
      date,
      body,
      config,
      submit,
    };
  },
};
</script>

<style></style>
